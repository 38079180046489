
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";
import moment from "moment";
import { getScraperSosmed } from "../streams";
import Swal from "sweetalert2/dist/sweetalert2.js";

interface MediaSosialForm {
  source: string;
  account: string;
  id_origin: string;
  content: string;
  date: string;
  emotion: string;
  engagement_count: number;
  engagement_detail: {
    like: number;
    comment: number;
  };
  followers: number;
  url: string;
  sentiment: string;
  carousel: [];
  esmr: number;
  id_account: string;
  photo: string;
  profile_image: string;
}

export default defineComponent({
  name: "input-berita-medsos",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  emit: ["success-input"],
  setup(props, { emit }) {
    const submitInstagram = ref<HTMLElement | null>(null);
    const getDataButton1 = ref<HTMLElement | null>(null);
    const loading = ref(false);
    const media_options = ref([]);
    const router = useRouter();
    const valueContent = ref("");
    const rateAccountMedia = ref(0);
    const lebarAccountMedia = ref(0);
    const isEnableField = ref(true);
    const shortcode = ref("");

    const MediaSosialForm = ref<MediaSosialForm>({
      source: "instagram",
      account: "",
      id_origin: "",
      content: "",
      date: "",
      emotion: "",
      engagement_count: 0,
      engagement_detail: {
        like: 0,
        comment: 0,
      },
      followers: 0,
      url: "",
      sentiment: "",
      carousel: [],
      esmr: 0,
      id_account: "",
      photo: "",
      profile_image: "",
    });

    const MediaSosialFormValidator = Yup.object().shape({
      url: Yup.string().required().label("URL Berita"),
      account: Yup.string().when("url", {
        is: () => MediaSosialForm.value.account === "",
        then: Yup.string().required("Nama Akun is a required field"),
        otherwise: Yup.string().notRequired(),
      }),
      url_account: Yup.string().when("url", {
        is: () => MediaSosialForm.value.id_account === "",
        then: Yup.string().required("URL Akun is a required field"),
        otherwise: Yup.string().notRequired(),
      }),
      content: Yup.string().when("url", {
        is: () => MediaSosialForm.value.content === "",
        then: Yup.string().required("Konten Berita is a required field"),
        otherwise: Yup.string().notRequired(),
      }),
      date: Yup.string().when("url", {
        is: () => MediaSosialForm.value.date === "",
        then: Yup.string().required("Tanggal Berita is a required field"),
        otherwise: Yup.string().notRequired(),
      }),
      engagement_detail_like: Yup.number()
        .min(0)
        .required()
        .label("Jumlah Like"),
      engagement_detail_comment: Yup.number()
        .min(0)
        .required()
        .label("Jumlah Komentar"),
      followers: Yup.number().min(0).required().label("Jumlah Follower"),
      emotion: Yup.string().label("Emotion"),
      sentiment: Yup.string().label("Sentimen"),
    });

    const isNumber = (evt) => {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        (charCode !== 46 || charCode !== 190)
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    };
    const resetForm = () => {
      shortcode.value = "";
      MediaSosialForm.value = {
        source: "instagram",
        account: "",
        id_origin: "",
        content: "",
        date: "",
        emotion: "",
        engagement_count: 0,
        engagement_detail: {
          like: 0,
          comment: 0,
        },
        followers: 0,
        url: "",
        sentiment: "",
        carousel: [],
        esmr: 0,
        id_account: "",
        photo: "",
        profile_image: "",
      };
    };
    const saveMedsos = () => {
      if (submitInstagram.value) {
        // Activate indicator
        submitInstagram.value.setAttribute("data-kt-indicator", "on");
        let loadout = MediaSosialForm.value;
        loadout.date = moment(loadout.date).format("YYYY-MM-DD HH:mm:ss");
        let params = {
          data: [loadout],
        };
        ApiService.post("/streams/save-socmed", { data: params })
          .then((response) => {
            if (response.status == 200) {
              const id = response.data.inserted_id.split(",");
              emit("success-input", id);
              Swal.fire({
                position: "top-end",
                icon: "success",
                toast: true,
                title: "Berhasil! Berita berhasil disimpan",
                showConfirmButton: false,
                timer: 3000,
              });
              resetForm();
            } else {
              Swal.fire({
                position: "top-end",
                icon: "error",
                toast: true,
                title: "Gagal! Berita gagal disimpan",
                showConfirmButton: false,
                timer: 3000,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            // Deactivate indicator
            submitInstagram.value?.removeAttribute("data-kt-indicator");
          });
      }
    };

    const remoteMethod = (query) => {
      if (query.length > 2) {
        loading.value = true;
        let url = "/media?media_like=" + query + "&limit=100&page=1";
        if (MediaSosialForm.value["source"] != "") {
          url = url + "&source=" + MediaSosialForm.value["source"];
        }
        ApiService.get(url)
          .then((response) => {
            media_options.value = response.data.data;
            return media_options.value;
          })
          .catch((error) => {
            console.log(error);
            loading.value = false;
          })
          .finally(() => {
            loading.value = false;
          });
        return [];
      } else {
        media_options.value = [];
      }
    };

    const convertText = (valueContent) => {
      if (MediaSosialForm.value["content"] != null) {
        let text = MediaSosialForm.value["content"].split("\n\n");
        let newText = "";

        for (let i = 0; i < text.length; i++) {
          let perTextArray = text[i].split("\n");
          for (let j = 0; j < perTextArray.length; j++) {
            if (perTextArray[j].charAt(perTextArray[j].length - 1) == "-") {
              newText += perTextArray[j].replace(/-/g, "");
            } else {
              newText += perTextArray[j] + " ";
            }
          }
          newText += "\n";
        }
        MediaSosialForm.value.content = newText;
        valueContent = newText;
      }
    };

    const getContentUrl = async () => {
      getDataButton1.value?.setAttribute("data-kt-indicator", "on");
      getDataButton1.value?.setAttribute("disabled", "disabled");
      let url = "/scraper/instagram/post";
      let params = {
        shortcode: shortcode.value.trim(),
      };
      let response = await getScraperSosmed(url, params);
      if (response["error"] || response["result"].error) {
        Swal.fire({
          text: "Gagal Mendapatkan Informasi Berita ! Silahkan Masukkan Ulang ID Postingan atau Masukkan ID Postingan Lain...",
          icon: "error",
          confirmButtonText: "Ok",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-light-primary",
          },
        });
        getDataButton1.value?.removeAttribute("data-kt-indicator");
        getDataButton1.value?.removeAttribute("disabled");
      } else {
        MediaSosialForm.value.account = response["result"].account;

        MediaSosialForm.value.id_origin = response["result"].id_origin;

        MediaSosialForm.value.url = response["result"].url;

        MediaSosialForm.value.content = response["result"].content;

        MediaSosialForm.value.date = response["result"].date;

        MediaSosialForm.value.emotion = response["result"].emotion;

        MediaSosialForm.value.engagement_count =
          response["result"].engagement_count;

        MediaSosialForm.value.engagement_detail.like =
          response["result"].engagement_detail.like;

        MediaSosialForm.value.engagement_detail.comment =
          response["result"].engagement_detail.comment;

        MediaSosialForm.value.followers = response["result"].followers;

        MediaSosialForm.value.sentiment = response["result"].sentiment;

        MediaSosialForm.value.carousel = response["result"].carousel;

        MediaSosialForm.value.esmr = response["result"].esmr;

        MediaSosialForm.value.id_account = response["result"].id_account;

        MediaSosialForm.value.photo = response["result"].photo;

        MediaSosialForm.value.profile_image = response["result"].profile_image;

        isEnableField.value = false;
        getDataButton1.value?.removeAttribute("data-kt-indicator");
        getDataButton1.value?.removeAttribute("disabled");
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Input Berita", ["Berita"]);
    });
    return {
      shortcode,
      submitInstagram,
      saveMedsos,
      resetForm,
      MediaSosialForm,
      MediaSosialFormValidator,
      loading,
      remoteMethod,
      media_options,
      convertText,
      valueContent,
      isNumber,
      rateAccountMedia,
      lebarAccountMedia,
      getContentUrl,
      isEnableField,
      getDataButton1,
    };
  },
  methods: {
    setEmotion(value) {
      if (value == this.MediaSosialForm.emotion) {
        this.MediaSosialForm.emotion = "";
      } else {
        this.MediaSosialForm.emotion = value;
      }
    },
    setSentiment(value) {
      if (value == this.MediaSosialForm.sentiment) {
        this.MediaSosialForm.sentiment = "";
      } else {
        this.MediaSosialForm.sentiment = value;
      }
    },
  },
});
