
import { defineComponent, onMounted, ref, defineEmits } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import { useRouter } from "vue-router";
import moment from "moment";
import { getScraperSosmed } from "../streams";
import Swal from "sweetalert2/dist/sweetalert2.js";

interface MediaSosialForm {
  source: string;
  account: string;
  content: string;
  date: string;
  engagement_count: number;
  engagement_detail: {
    comment: number;
    favorite: number;
    like: number;
    view: number;
  };
  esmr: string;
  followers: number;
  id_account: string;
  id_origin: string;
  sentiment: string;
  emotion: string;
  thumbnail: null;
  title: string;
  url: string;
}

export default defineComponent({
  name: "input-berita-medsos",
  components: {
    ErrorMessage,
    Field,
    // Form,
  },
  emit: ["success-input"],
  setup(props, { emit }) {
    const submitYoutube = ref<HTMLElement | null>(null);
    const getDataButton1 = ref<HTMLElement | null>(null);
    const loading = ref(false);
    const router = useRouter();
    const valueContent = ref("");
    const isEnableField = ref(true);
    const ids = ref("");
    const data: Array<MediaSosialForm> = [];
    const MediaSosialForm = ref<Array<MediaSosialForm>>(data);
    const isEmpty = ref(true);
    const MediaSosialFormValidator = Yup.object().shape({
      title0: Yup.string().required().label("Judul Berita"),
      url_berita0: Yup.string().required().label("Url Berita"),
      account0: Yup.string().required().label("Nama Akun"),
      url_akun0: Yup.string().required().label("Url Akun"),
      engagement_detail_like0: Yup.string().required().label("Jumlah Like"),
      engagement_detail_comment0: Yup.string()
        .required()
        .label("Jumlah Komentar"),
      engagement_detail_viewer0: Yup.string().required().label("Jumlah Viewer"),
      engagement_detail_favorite0: Yup.string()
        .required()
        .label("Jumlah Favorite"),
      followers0: Yup.string().required().label("Jumlah Followers"),
    });
    const resetForm = () => {
      ids.value = "";
      MediaSosialForm.value = data;
    };
    const saveMedsos = () => {
      if (submitYoutube.value) {
        submitYoutube.value.setAttribute("data-kt-indicator", "on");
        let loadout = MediaSosialForm.value;
        loadout.forEach(
          (el) => (el.date = moment(el.date).format("YYYY-MM-DD HH:mm:ss"))
        );
        let params = {
          data: loadout,
        };
        ApiService.post("/streams/save-socmed", { data: params })
          .then((response) => {
            if (response.status == 200) {
              const id = response.data.inserted_id.split(",");
              emit("success-input", id);
              Swal.fire({
                position: "top-end",
                icon: "success",
                toast: true,
                title: "Berhasil! Berita berhasil disimpan",
                showConfirmButton: false,
                timer: 3000,
              });
              resetForm();
            } else {
              Swal.fire({
                position: "top-end",
                icon: "error",
                toast: true,
                title: "Gagal! Berita gagal disimpan",
                showConfirmButton: false,
                timer: 3000,
              });
            }
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            submitYoutube.value?.removeAttribute("data-kt-indicator");
          });
      }
    };
    const convertText = (index) => {
      if (MediaSosialForm.value[index]["content"] != null) {
        let text = MediaSosialForm.value[index]["content"].split("\n\n");
        let newText = "";

        for (let i = 0; i < text.length; i++) {
          let perTextArray = text[i].split("\n");
          for (let j = 0; j < perTextArray.length; j++) {
            if (perTextArray[j].charAt(perTextArray[j].length - 1) == "-") {
              newText += perTextArray[j].replace(/-/g, "");
            } else {
              newText += perTextArray[j] + " ";
            }
          }
          newText += "\n";
        }
        MediaSosialForm.value[index]["content"] = newText;
      }
    };
    const getContentUrl = async () => {
      MediaSosialForm.value = [];
      getDataButton1.value?.setAttribute("data-kt-indicator", "on");
      getDataButton1.value?.setAttribute("disabled", "disabled");
      let url = "/scraper/youtube/post";
      let id = ids.value.replaceAll(" ", "");
      let params = {
        ids: id,
      };
      let response = await getScraperSosmed(url, params);
      if (response["error"] || response["result"].error) {
        Swal.fire({
          text: "Gagal Mendapatkan Informasi Berita ! Silahkan Masukkan Ulang ID Postingan atau Masukkan ID Postingan Lain...",
          icon: "error",
          confirmButtonText: "Ok",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-light-primary",
          },
        });
        getDataButton1.value?.removeAttribute("data-kt-indicator");
        getDataButton1.value?.removeAttribute("disabled");
      } else {
        isEmpty.value = false;
        for (let i = 0; i < response["result"].length; i++) {
          let obj = {
            source: "youtube",
            account: response["result"][i].account,
            content: response["result"][i].content,
            date: response["result"][i].date,
            engagement_count: response["result"][i].engagement_count,
            engagement_detail: {
              comment: response["result"][i].engagement_detail.comment,
              favorite: response["result"][i].engagement_detail.favorite,
              like: response["result"][i].engagement_detail.like,
              view: response["result"][i].engagement_detail.view,
            },
            esmr: response["result"][i].esmr,
            followers: response["result"][i].followers,
            id_account: response["result"][i].id_account,
            id_origin: response["result"][i].id_origin,
            sentiment: "",
            emotion: "",
            thumbnail: response["result"][i].thumbnail,
            title: response["result"][i].title,
            url: response["result"][i].url,
          };
          MediaSosialForm.value.push(obj);
        }
        isEnableField.value = false;
        getDataButton1.value?.removeAttribute("data-kt-indicator");
        getDataButton1.value?.removeAttribute("disabled");
      }
    };

    onMounted(() => {
      setCurrentPageBreadcrumbs("Input Berita", ["Berita"]);
    });
    return {
      ids,
      data,
      isEmpty,
      submitYoutube,
      saveMedsos,
      resetForm,
      MediaSosialForm,
      MediaSosialFormValidator,
      loading,
      convertText,
      valueContent,
      getContentUrl,
      isEnableField,
      getDataButton1,
    };
  },
  methods: {
    setEmotion(index, value) {
      if (value == this.MediaSosialForm[index].emotion) {
        this.MediaSosialForm[index].emotion = "";
      } else {
        this.MediaSosialForm[index].emotion = value;
      }
    },
    setSentiment(index, value) {
      if (value == this.MediaSosialForm[index].sentiment) {
        this.MediaSosialForm[index].sentiment = "";
      } else {
        this.MediaSosialForm[index].sentiment = value;
      }
    },
  },
});
