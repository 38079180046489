
import InstagramFormInput from "./components/InstagramFormInput.vue";
import YoutubeFormInput from "./components/YoutubeFormInput.vue";
import TwitterFormInput from "./components/TwitterFormInput.vue";
import FacebookFormInput from "./components/FacebookFormInput.vue";
import TiktokFormInput from "./components/TiktokFormInput.vue";
import { defineComponent, onMounted, ref } from "vue";
import { Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "socmed-input",
  components: {
    InstagramFormInput,
    YoutubeFormInput,
    TwitterFormInput,
    FacebookFormInput,
    TiktokFormInput,
    Field,
    Form,
  },
  setup() {
    const source = ref("");
    const is_show_alert = ref(false);
    const inserted_id = ref([]);
    const options = ref({
      source: [
        {
          title: "Youtube",
          value: "youtube",
        },
        {
          title: "Instagram",
          value: "instagram",
        },
        {
          title: "Twitter",
          value: "twitter",
        },
        {
          title: "Facebook",
          value: "facebook",
        },
        {
          title: "Tiktok",
          value: "tiktok",
        },
      ],
    });

    // FUNCTION
    const showDetail = (id: string) => {
      window.open("/berita/detail/" + id);
    };
    const getSuccessInputID = (id: any) => {
      inserted_id.value = id;
      is_show_alert.value = true;
      setTimeout(() => {
        is_show_alert.value = false;
      }, 30000);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    };

    // LIFECYCLE HOOKS
    onMounted(() => {
      setCurrentPageBreadcrumbs("Input Berita", ["Berita"]);
    });
    return {
      source,
      is_show_alert,
      inserted_id,
      options,
      showDetail,
      getSuccessInputID,
    };
  },
});
